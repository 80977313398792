import React, { useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { RxDashboard } from "react-icons/rx";
import { CgNotes } from "react-icons/cg";
import { PiPresentationBold, PiStudent } from "react-icons/pi";
import { RiUser3Line } from "react-icons/ri";
import { AiOutlineBank } from "react-icons/ai";
import { FiSettings } from "react-icons/fi";
import { BiLogOut } from "react-icons/bi";
import colors from './colors';
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import { sessionLogout } from '../../@redux/dispatch/logout/GetLogoutReducer';
import { MdPayments } from "react-icons/md";
import { getCounUniversity, getUniversity } from '../../@redux/dispatch/GetUniversitiesReducer';
import { FaRocketchat } from "react-icons/fa";
import apiUrl from '../../@redux/ApiUrl';
import toastr from "toastr";
// import Logo from './../../../public/images/logo.svg'

function UnivLeftSidebar() {
    const [userData, setUserData] = useState("")
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => (state.config?.userData))
    const userRole = useSelector((state) => (state.config.profile_id))
    console.log("For leftsidebar related information come here ", user)
    const imgUrl = useSelector(state => state.customisation.logoUrlRes)

    console.log("image url value i am getting as is here ", imgUrl?.logo)

    const brandingAttr = useSelector(state => (state.config?.userAttributes))
    console.log("user attributes are", brandingAttr)
    const brandingValue = brandingAttr?.find(item => item.Name === "custom:branding")?.Value || false
    console.log("branding value is ", brandingValue)
    const handleUniv = () => {
        if (userRole !== 'counsellor')
            dispatch(getUniversity())
        else {
            dispatch(getCounUniversity())
        }
    }

    const handleLogout = async () => {
        dispatch(sessionLogout(navigate));
        try {
            // Make a request to the backend logout endpoint
            const response = await axios.post(`${apiUrl}/api/logout`); // Replace '/api/logout' with your actual backend endpoint

            // Check the response from the server
            if (response.data.success) {
                // Successful logout
                navigate("/students")
                toastr.success("Logged out successfully");
                console.log('Logout successful');
            } else {
                // Logout failed
                console.error('Logout failed:', response.data.error);
            }
        } catch (error) {
            // Handle any errors that occurred during the request
            console.error('Error during logout request:', error.message);
        }

    }
    return (

        <nav id="" className="col-md-3 col-lg-2 col-xl-2 col-xxl-2 sidebar desktop overflow-auto pt-0">
            <div className="position-sticky pt-3 sidebar-sticky autoScroll">
                <div className="side-header sticky-top bg-white py-3">
                    <Link to="/" className=' '>

                        <img src={(imgUrl && imgUrl?.logo && imgUrl?.logo?.endsWith("undefined")) ? '/images/logo.svg' : imgUrl?.logo} width={151} height={24} />
                    </Link>

                </div>
                <div className="avatar">
                    <img src="/images/profile-pic.png" alt="Image goes here" />
                    {((userRole === "student"))
                        ?
                        <p className="username">
                            {user?.Full_Name}
                        </p>
                        :
                        (
                            (userRole === "counsellor")
                                ?
                                <p className="username">{user?.Name}</p>
                                :
                                <p className="username">{user?.Vendor_Name}</p>)
                    }
                </div>
                <ul className="nav flex-column">
                    <li className="nav-item">
                        <NavLink className="nav-link " to="/dashboard" activeClassName="active ">
                            <RxDashboard /> Dashboard
                        </NavLink>
                    </li>
                    {console.log("univerLeftUserdata who is here:", userData?.data?.[0]?.profile)}

                    {(userRole !== 'student') && (
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/student-list" activeClassName="active">
                                <PiStudent /> Students
                            </NavLink>
                        </li>
                    )}
                    {(userRole !== 'student' && userRole !== 'agent') && (
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/agents" activeClassName="active">
                                <PiStudent /> Agents
                            </NavLink>
                        </li>
                    )}
                    {(userRole !== 'student' && userRole !== 'agent' && userRole !== 'counsellor') && (
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/counsellors" activeClassName="active">
                                <PiStudent /> Counsellors
                            </NavLink>
                        </li>
                    )}
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/applications" activeClassName="active">
                            <CgNotes /> Applications
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/courses" activeClassName="active">
                            <PiPresentationBold /> Courses
                        </NavLink>
                    </li>
                    <li className="nav-item" onClick={handleUniv}>
                        <NavLink className="nav-link " to="/universities" activeClassName="active">
                            <AiOutlineBank /> Universities
                        </NavLink>
                    </li>
                    <hr />
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/profile" activeClassName="active">
                            <RiUser3Line /> Profile
                        </NavLink>
                    </li>
                     {
                        (userRole === 'agent') && (
                            brandingValue && (<li className="nav-item">
                                <NavLink className="nav-link temp" to="/branding" activeClassName="active">
                                    <FiSettings /> Branding
                                </NavLink>
                            </li>)
                        )
                    } 

                    {/* <li className="nav-item">
                       
                        <a href='https://pay.edbucket.com/' target='_blank' className="nav-link "><MdPayments /> EdPay</a>

                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/chatbot" activeClassName="active">
                            <FaRocketchat /> Virtual Assistant
                        </NavLink>
                    </li> */}
                    {/* <li className="nav-item">
                        <NavLink className="nav-link temp" to="/apiCall" activeClassName="active">
                            <FiSettings /> API Call
                        </NavLink>
                    </li> */}
                    <li className="nav-item">
                        <NavLink className="nav-link temp" to="/" onClick={handleLogout} activeClassName="active">
                            <BiLogOut /> Logout
                        </NavLink>
                    </li>
                </ul>
            </div>

        </nav>

    )
}

export default UnivLeftSidebar
